import { fetchGalleryModels, Model } from '../data';
import React, { useEffect, useState } from 'react';

export interface ModelState {
    models: readonly Model[];
    selectedModel?: Model;
    setSelectedModel: (model: Model | undefined) => void;
}

export const ModelStateContext = React.createContext<ModelState | undefined>(undefined);

export interface ModelStateProviderProps {
    children?: React.ReactNode;
}

export function ModelStateProvider({ children }: ModelStateProviderProps) {
    const [state, setState] = useState<ModelState>({
        models: [],
        setSelectedModel,
    });

    function setSelectedModel(model: Model | undefined) {
        setState(state => ({
            ...state,
            selectedModel: model,
        }));
    }

    useEffect(() => {
        fetchGalleryModels().then(models => {
            setState(state => ({
                ...state,
                models: models,
                selectedModel: models[0],
            }));
        });
    }, []);

    return <ModelStateContext.Provider value={state}>{children}</ModelStateContext.Provider>;
}

export function useModelState() {
    const context = React.useContext(ModelStateContext);
    if (!context) {
        throw new Error('useModelsState must be used within a ModelStateProvider');
    }

    return context;
}
