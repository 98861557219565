import React from 'react';

export interface StyleProps {
    bgColorDark: string;
    modelItemHeight: number;
}

export const StylePropsContext = React.createContext<StyleProps | undefined>(undefined);

export interface StylePropsProviderProps {
    children?: React.ReactNode;
}

const computedStyle = getComputedStyle(document.body);

const styleProps: StyleProps = {
    bgColorDark: computedStyle.getPropertyValue('--bg-color-dark')?.trim(),
    modelItemHeight: parseInt(computedStyle.getPropertyValue('--model-item-height')?.trim()),
};

export function StylePropsProvider({ children }: StylePropsProviderProps) {
    return <StylePropsContext.Provider value={styleProps}>{children}</StylePropsContext.Provider>;
}

export function useStyleProps() {
    const context = React.useContext(StylePropsContext);
    if (!context) {
        throw new Error('useStyleProps must be used within a StylePropsProvider');
    }

    return context;
}
