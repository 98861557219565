import React from 'react';
import Sidebar from './Sidebar';
import styles from './Main.module.css';
import Viewer from './Viewer';
import Toolbar, { ToolbarItem } from './Toolbar';
import Header from './Header';
import { concatCssClasses } from '../utils';
import { useDisplayState } from '../state/display';
import { ReactComponent as Menu } from '../assets/images/menu.svg';

interface OverlayProps {
    visible: boolean;
}

function Overlay({ visible }: OverlayProps) {
    if (!visible) {
        return null;
    }

    return <div className={styles.overlay}></div>;
}

function Main() {
    const { showSidebar, isSmallScreen, setShowSidebar } = useDisplayState();

    const toolbarItems: ToolbarItem[] = [
        {
            icon: Menu,
            tooltip: 'Toggle sidebar',
            execute: () => setShowSidebar(!showSidebar),
        },
    ];

    return (
        <div className={styles.container}>
            <Header className={styles.header} />
            <Toolbar className={styles.toolbar} items={toolbarItems} />
            <Overlay visible={isSmallScreen && showSidebar} />
            <Viewer className={styles.viewer} />
            <Sidebar className={concatCssClasses(styles.sidebar, !showSidebar && styles.hidden)} showCloseButton={isSmallScreen} />
        </div>
    );
}

export default Main;
