import React from 'react';
import styles from './Toolbar.module.css';
import { concatCssClasses } from '../utils';
import { useClientRect } from '../hooks/client-rect';

export interface ToolbarItem {
    icon: React.FC<React.SVGProps<any>>;
    tooltip: string;
    execute: () => void;
}

export interface ToolbarProps {
    items: ToolbarItem[];
    className?: string;
}

interface ToolbarButtonProps {
    item: ToolbarItem;
    height?: number;
}

function ToolbarButton({ item, height }: ToolbarButtonProps) {
    const size = height ? height + 'px' : undefined;

    return (
        <div className={styles.button} title={item.tooltip} onClick={item.execute}>
            <item.icon width={size} height={size} />
        </div>
    );
}

function Toolbar({ className, items }: ToolbarProps) {
    const [containerRect, containerRef] = useClientRect<HTMLDivElement>();

    const buttons = items.map((item, index) => <ToolbarButton key={index} item={item} height={containerRect?.height} />);

    return (
        <div ref={containerRef} className={concatCssClasses(className, styles.container)}>
            {buttons}
        </div>
    );
}

export default Toolbar;
