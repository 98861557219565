import React, { useCallback, useState } from 'react';
import styles from './Viewer.module.css';
import { concatCssClasses, hexStringToColor4Like } from '../utils';
import { serverUrl } from '../data';
import { useModelState } from '../state/model';
import { useStyleProps } from '../state/style';
import { createViewer } from '../assets/js/ceetron-viewer';

export interface ViewerProps {
    className?: string;
}

function Viewer({ className }: ViewerProps) {
    const { selectedModel } = useModelState();
    const { bgColorDark } = useStyleProps();

    // TODO: Use proper type when ceetron-viewer.d.ts is available
    const [viewer, setViewer] = useState<any>();

    const viewerRef = useCallback(
        (viewerElem: HTMLDivElement) => {
            if (!viewerElem || !bgColorDark || !selectedModel) {
                return;
            }

            if (viewer) {
                viewer.update({
                    model: {
                        key: selectedModel?.key,
                    },
                });
            } else {
                setViewer(
                    createViewer({
                        rootElementOrSelector: viewerElem,
                        model: {
                            key: selectedModel?.key,
                        },
                        cugServerUrl: serverUrl,
                        showAnimationToolbar: true,
                        showMainToolbar: true,
                        enablePicking: true,
                        autoStartCameraSpinAnimation: true,
                        autoStartAnimation: true,
                        backgroundColor: hexStringToColor4Like(bgColorDark),
                    }),
                );
            }
        },
        [selectedModel, bgColorDark],
    );

    if (!selectedModel) {
        return null;
    }

    return <div ref={viewerRef} className={concatCssClasses(className, styles.viewer)}></div>;
}

export default Viewer;
