import React, { useEffect, useState } from 'react';

export interface DisplayState {
    isSmallScreen: boolean;
    showSidebar: boolean;
    setShowSidebar: (value: boolean) => void;
}

export const DisplayStateContext = React.createContext<DisplayState | undefined>(undefined);

export interface DisplayStateProviderProps {
    children?: React.ReactNode;
}

export function DisplayStateProvider({ children }: DisplayStateProviderProps) {
    const [state, setState] = useState<DisplayState>({
        isSmallScreen: getIsSmallScreen(),
        showSidebar: true,
        setShowSidebar,
    });

    function setShowSidebar(value: boolean) {
        setState(state => ({
            ...state,
            showSidebar: value,
        }));
    }

    useEffect(() => {
        const handler = () => {
            const isSmallScreen = getIsSmallScreen();
            if (state.isSmallScreen !== isSmallScreen) {
                setState(state => ({
                    ...state,
                    isSmallScreen,
                }));
            }
        };

        window.addEventListener('resize', handler);

        return () => window.removeEventListener('resize', handler);
    }, [state]);

    return <DisplayStateContext.Provider value={state}>{children}</DisplayStateContext.Provider>;
}

export function useDisplayState() {
    const context = React.useContext(DisplayStateContext);
    if (!context) {
        throw new Error('useDisplayState must be used within a DisplayStateProvider');
    }

    return context;
}

function getIsSmallScreen() {
    return window.innerWidth <= 1200;
}
