import React, { useState } from 'react';
import styles from './Expander.module.css';
import { concatCssClasses } from '../utils';
import { ReactComponent as ChevronRight } from '../assets/images/chevron-right.svg';

export interface ExpanderProps {
    header: string;
    children?: React.ReactElement;
    className?: string;
    contentHeight: string;
}

function Expander({ header, children, className, contentHeight }: ExpanderProps) {
    const [isExpanded, setIsExpanded] = useState(true);

    return (
        <div className={concatCssClasses(className, styles.container, isExpanded && styles.expanded)}>
            <div className={styles.header} onClick={() => setIsExpanded(!isExpanded)}>
                <ChevronRight className={styles.headerIcon} />
                <div className={styles.headerText}>{header}</div>
            </div>
            <div className={styles.content} style={{ height: isExpanded && contentHeight ? contentHeight : 0 }}>
                {children}
            </div>
        </div>
    );
}

export default Expander;
