import React from 'react';
import styles from './Header.module.css';
import { concatCssClasses } from '../utils';
import logo from '../assets/images/demoapp_banner.png';

export interface HeaderProps {
    className?: string;
}

function Header({ className }: HeaderProps) {
    return (
        <div className={concatCssClasses(className, styles.container)}>
            <img src={logo} alt="Ceetron Envision Demo Web Viewer" className={styles.logo} />
            <div>Demo Web Viewer</div>
        </div>
    );
}

export default Header;
