import { useCallback, useState } from 'react';

export function useClientRect<T extends HTMLElement>(): [DOMRect | null, (node: T) => void] {
    const [rect, setRect] = useState<DOMRect | null>(null);

    const ref = useCallback((node: T) => {
        if (node !== null) {
            setRect(node.getBoundingClientRect());
        }
    }, []);

    return [rect, ref];
}
