export function concatCssClasses(...classes: (string | undefined | false)[]) {
    return classes.filter(c => !!c).join(' ');
}

export function hexStringToColor4Like(str: string) {
    if (!str) {
        return undefined;
    }
    if (str.charAt(0) === '#') {
        str = str.substring(1);
    }
    if (str.length === 3) {
        str = `${str[0]}${str[0]}${str[1]}${str[1]}${str[2]}${str[2]}`;
    }
    if (str.length !== 6) {
        return undefined;
    }

    const r = str.substring(0, 2);
    const g = str.substring(2, 4);
    const b = str.substring(4, 6);

    return {
        r: parseInt(r, 16) / 255,
        g: parseInt(g, 16) / 255,
        b: parseInt(b, 16) / 255,
        a: 1,
    };
}
