import React, { useState } from 'react';
import styles from './Sidebar.module.css';
import { Model } from '../data';
import { concatCssClasses } from '../utils';
import Expander from './Expander';
import Toolbar, { ToolbarItem } from './Toolbar';
import { ReactComponent as Close } from '../assets/images/close.svg';
import { useModelState } from '../state/model';
import { useDisplayState } from '../state/display';
import { useStyleProps } from '../state/style';

export interface SidebarProps {
    className?: string;
    showCloseButton: boolean;
}

interface SidebarToolbarProps {
    visible: boolean;
    items: ToolbarItem[];
}

interface ModelListProps {
    models: readonly Model[];
    selectedModel?: Model;
    onModelSelected: (model: Model) => void;
}

interface ModelListItemProps {
    model: Model;
    isSelected: boolean;
    onClick: () => void;
}

interface ImageProps {
    url: string | undefined;
    alt: string;
}

function Image({ url, alt }: ImageProps) {
    if (!url) {
        return <div className={styles.modelItemImage}></div>;
    }

    return <img className={styles.modelItemImage} alt={alt} src={url} />;
}

function ModelListItem({ model, isSelected, onClick }: ModelListItemProps) {
    const [snapshotUrl, setSnapshotUrl] = useState<string | undefined>(model.snapshotUrl);

    if (!snapshotUrl) {
        model.fetchSnapshotUrl.then(s => setSnapshotUrl(s));
    }

    return (
        <div className={concatCssClasses(styles.modelItemContainer, isSelected && styles.selected)} onClick={onClick}>
            <Image url={snapshotUrl} alt={model.name} />
            <div className={styles.modelItemTextContainer}>
                <div>{model.name}</div>
                <div>{model.description}</div>
            </div>
        </div>
    );
}

function ModelList({ models, selectedModel, onModelSelected }: ModelListProps) {
    const modelItems = models.map(model => (
        <ModelListItem key={model.key} model={model} isSelected={model === selectedModel} onClick={() => onModelSelected(model)} />
    ));

    return <div>{modelItems}</div>;
}

function SidebarToolbar({ visible, items }: SidebarToolbarProps) {
    if (!visible) {
        return null;
    }

    return <Toolbar className={styles.toolbar} items={items} />;
}

function Sidebar({ className, showCloseButton }: SidebarProps) {
    const { setShowSidebar, isSmallScreen } = useDisplayState();
    const { modelItemHeight } = useStyleProps();
    const { models, selectedModel, setSelectedModel } = useModelState();

    const toolbarItems: ToolbarItem[] = [
        {
            icon: Close,
            tooltip: 'Close',
            execute: () => setShowSidebar(false),
        },
    ];

    function handleModelSelect(model: Model) {
        setSelectedModel(model);

        if (isSmallScreen) {
            setShowSidebar(false);
        }
    }

    const modelListHeight = models.length * modelItemHeight + 'px';

    return (
        <div className={concatCssClasses(className, styles.container)}>
            <SidebarToolbar visible={showCloseButton} items={toolbarItems} />
            <Expander className={styles.expander} header="Sample models" contentHeight={modelListHeight}>
                <ModelList models={models} selectedModel={selectedModel} onModelSelected={handleModelSelect} />
            </Expander>
        </div>
    );
}

export default Sidebar;
