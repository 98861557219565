import React from 'react';
import { ModelStateProvider } from './state/model';
import { DisplayStateProvider } from './state/display';
import { StylePropsProvider } from './state/style';
import Main from './components/Main';

function App() {
    return (
        <ModelStateProvider>
            <DisplayStateProvider>
                <StylePropsProvider>
                    <Main />
                </StylePropsProvider>
            </DisplayStateProvider>
        </ModelStateProvider>
    );
}

export default App;
