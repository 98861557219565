interface ModelDTO {
    key: string;
    name: string;
    description: string;
}

export class Model {
    private _snapshotUrl?: string;

    get snapshotUrl() {
        return this._snapshotUrl;
    }

    constructor(
        readonly key: string,
        readonly name: string,
        readonly description: string,
        readonly fetchSnapshotUrl: Promise<string | undefined>,
    ) {
        fetchSnapshotUrl.then(s => (this._snapshotUrl = s));
    }
}

export const serverUrl = process.env.REACT_APP_SERVER_URL ?? '/';

export async function fetchGalleryModels(): Promise<Model[]> {
    const url = serverUrl + 'api/v1/models';

    try {
        const res = await fetch(url);
        const dtos: ModelDTO[] = await res.json();
        return dtos.map(dto => new Model(dto.key, dto.name, dto.description, fetchCaseSnapshot(dto.key)));
    } catch (e) {
        console.error(e);
        return [];
    }
}

async function fetchCaseSnapshot(key: string): Promise<string | undefined> {
    if (!key) {
        return;
    }

    const url = `${serverUrl}api/v1/snapshot/${key}`;

    try {
        const res = await fetch(url);
        const buffer = await res.arrayBuffer();
        const base64Str = arrayBufferToBase64(buffer);
        return `data:image/png;base64,${base64Str}`;
    } catch (e) {
        console.error(e);
        return undefined;
    }
}

function arrayBufferToBase64(arrayBuffer: ArrayBuffer) {
    const bytes = new Uint8Array(arrayBuffer);

    return btoa(
        Array.from({ length: bytes.byteLength })
            .map((e, i) => String.fromCharCode(bytes[i]))
            .join(''),
    );
}
